import React from 'react';
import './InvalidLink.css';

const InvalidLink = () => {
    return (
        <div className="invalid-link-screen">
            <div className="invalid-link-message">
                <h1>Oops! Something Went Wrong</h1>
                <p>
                    The link is invalid or this interview has already taken place. If you believe this is an
                    error, please contact support or your recruiter for assistance.
                </p>
            </div>
        </div>
    );
};

export default InvalidLink;
