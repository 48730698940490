import React from 'react';
import './ThankYouScreen.css';

const ThankYouScreen = () => {
    return (
        <div className="thank-you-screen">
            <div className="thank-you-message">
                <h1>Thank You!</h1>
                <p>Thanks for taking the interview with us. This interview is now closed.</p>
                <p className="additional-message">
                    We appreciate your time and effort. Stay tuned for our response!
                </p>
            </div>
        </div>
    );
};

export default ThankYouScreen;
